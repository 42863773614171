import { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { isEmpty, isNil } from 'lodash'

import { useAppState } from '@/stores'
import { formatHealthFactor, numberFormat, formatBIUnit } from '@/utils/format'
import { useMarketData } from '@/utils/dataHooks'
import { incentiveDataEnum } from '@/config/constants/assets'

import HideShowButton from '@/components/HideShowButton'
import HealthFactorNumber from '@/components/Common/HealthFactorNumber'
import Skeleton from '@/components/Common/Skeleton'

import TableBorrowsItem from './TableBorrowsItem'
import TableBorrowsMobile from './TableBorrowsMobile'

function TableBorrows() {
  const {
    walletAccountStore: { userAccount, accountReserves, projectTokenPrice }
  } = useAppState()
  const [isShowMore, setIsShowMore] = useState(true)
  const { data: marketsData } = useMarketData()
  const markets = useMemo(() => marketsData?.markets || [], [marketsData])
  const { annualEmission } = incentiveDataEnum

  const borrowing = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.borrowing?.amount && Number(item.borrowing.amount) > 0),
    [accountReserves]
  )

  const totalDebtUSD = userAccount.totalDebtETH ? formatBIUnit(userAccount.totalDebtETH, 18) : 0
  const totalAvailableBorrowUSD = userAccount.availableBorrowsETH
    ? formatBIUnit(userAccount.availableBorrowsETH, 18)
    : 0

  const borrowPowerUsed = (totalDebtUSD / (totalDebtUSD + totalAvailableBorrowUSD)) * 100

  const { totalBorrowed, apyBorrowed, apyReward } = useMemo(() => {
    const getRewardApy = (address) => {
      const secondsPerYear = 60 * 60 * 24 * 365
      const market = markets.find((market) => market.id === address)
      if (!market || !projectTokenPrice) return null

      const totalAllocPoint = market?.totalAllocPoint ?? 0
      const borrowAllocPoint = market?.borrowAllocPoint ?? 0
      const totalBorrowedRC = market?.totalBorrowBalanceUSD || 1

      const rewardBorrowRC = (borrowAllocPoint / totalAllocPoint) * annualEmission * projectTokenPrice
      const aprBorrow = rewardBorrowRC / totalBorrowedRC
      const apy = (1 + aprBorrow / secondsPerYear) ** secondsPerYear - 1

      return !Number.isFinite(apy) ? null : apy
    }

    return borrowing.reduce(
      (acc, item) => {
        if (!item?.borrowing?.amount || !item.price) return acc

        const _borrowedRC = item.price * formatBIUnit(item.borrowing.amount, item.decimals)
        const _totalBorrowed = acc.totalBorrowed + _borrowedRC
        const _rewardAPY = getRewardApy(item.address)
        const _apyBorrowed = acc.apyBorrowed + _borrowedRC * (Number(item.borrowing.apy) / 1e27)
        const _apyReward = acc.apyReward + _borrowedRC * _rewardAPY
        return { totalBorrowed: _totalBorrowed, apyBorrowed: _apyBorrowed, apyReward: _apyReward }
      },
      { totalBorrowed: 0, apyBorrowed: 0, apyReward: 0 }
    )
  }, [borrowing, annualEmission, projectTokenPrice, markets])

  const avgAPY = (apyReward * 100) / totalBorrowed - (apyBorrowed * 100) / totalBorrowed

  if (!isEmpty(accountReserves) && totalBorrowed === 0) {
    return (
      <div className="card w-full flex-1 md:mt-8 md:w-auto lg:mt-0">
        <div className="p-4 md:p-8">
          <div className="text900 pb-4 text-lg font-semibold">Your borrows</div>
          <p className="text400 text-base font-medium">Nothing borrowed yet</p>
        </div>
      </div>
    )
  }

  return (
    <div className="card overflow-visible md:mt-8 lg:mt-0">
      <div className="border300 box-border block px-4 py-4 md:px-6 md:pb-0 md:pt-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="text900 box-border flex-1 border-0 text-lg font-semibold">Your borrows</div>
          </div>
          <HideShowButton isShow={isShowMore} toggleButton={setIsShowMore} className="sm:invisible" />
        </div>
      </div>
      <div className="block px-4 py-4 md:px-6 md:py-5">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-wrap items-center gap-2 md:gap-4">
            {/* <div className="text600 border300 border rounded border-white-200 p-1 box-border text-sm leading-4 font-normal"> */}
            <div className="border200 text600 box-border rounded border p-[7px] text-sm font-normal leading-4">
              Balance
              <span className="text900 ml-1">
                {!isEmpty(accountReserves) && !isNil(userAccount?.totalDebtETH) ? (
                  <>${numberFormat(totalDebtUSD, { isSymbolHide: true })}</>
                ) : (
                  <Skeleton text={16} />
                )}
              </span>
            </div>
            <div className="border200 text600 box-border rounded border p-[7px] text-sm font-normal leading-4">
              APY
              <span className="text900 ml-1">
                {!isEmpty(accountReserves) && !isNil(userAccount?.totalDebtETH) ? (
                  <>{numberFormat(avgAPY)}%</>
                ) : (
                  <Skeleton text={10} />
                )}
              </span>
            </div>
            <div className="border200 text600 box-border flex rounded border p-[7px] text-sm font-normal leading-4">
              <div className="flex items-center">
                <div className="box-border flex border-0">
                  Power used
                  <span className="text900 ml-1">
                    {!isEmpty(accountReserves) && !isNil(userAccount?.totalDebtETH) ? (
                      <>{numberFormat(borrowPowerUsed)}%</>
                    ) : (
                      <Skeleton text={12} />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="border200 text600 box-border flex rounded border p-[7px] text-sm font-normal leading-4">
              <div className="flex items-center">
                <div className="box-border flex border-0">
                  Health factor
                  <span className="text900 ml-1">
                    {!isEmpty(accountReserves) && !isNil(userAccount?.healthFactor) ? (
                      <HealthFactorNumber
                        value={formatHealthFactor(
                          userAccount?.healthFactor.toString(),
                          userAccount?.totalDebtETH.toString()
                        )}
                      />
                    ) : (
                      <Skeleton text={10} />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-border hidden p-4 md:flex md:px-6 md:py-3 ">
        {/* <div className="flex w-full"> */}
        <div className="text600 flex w-full text-xs">
          <div className="flex w-[20%]">Assets</div>
          <div className="flex w-[19%] justify-end">Debit</div>
          <div className="flex w-[16%] justify-end">APY</div>
          {/* <div className="flex text-s w-[12.5%]">APY type</div> */}
        </div>
      </div>
      <div className="hidden md:block">
        {accountReserves &&
          borrowing.map((item) => (
            <TableBorrowsItem asset={item.borrowing.symbol} contract={item.address} key={item.address} />
          ))}
        {isEmpty(accountReserves) && accountReserves !== null && (
          <div className="flex flex-col">
            {Array(6)
              .fill({})
              .map((_, index) => (
                <div
                  className="box-border flex items-center justify-between px-4 py-[18px]"
                  key={'skeleton' + index} // eslint-disable-line
                >
                  <Skeleton className="!my-0 h-9 rounded-full" classNameWrapper="max-w-full" />
                </div>
              ))}
          </div>
        )}
      </div>
      {isShowMore && (
        <div className="px-0 py-0 md:hidden">
          {isEmpty(accountReserves) && <TableBorrowsMobileSkeleton />}
          {accountReserves &&
            borrowing.map((item) => (
              <TableBorrowsMobile asset={item.borrowing.symbol} contract={item.address} key={item.address} />
            ))}
        </div>
      )}
    </div>
  )
}

export default observer(TableBorrows)

const TableBorrowsMobileSkeleton = () => (
  <div className="flex flex-col">
    {Array(6)
      .fill({})
      .map((_, index) => (
        <div
          className="box-border flex w-full flex-col md:hidden"
          key={'skeleton' + index} // eslint-disable-line
        >
          <div className="mt-2 p-4">
            <div className="flex w-full flex-col gap-4">
              <div className="flex items-center gap-4">
                <Skeleton className="h-[40px] w-[40px] rounded-full" text={10} />
                <Skeleton className="h-[23px]" text={16} />
              </div>
              <Skeleton className="!my-0 !mb-2 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 !mb-[2px] h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <div className="flex justify-between gap-4">
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
)
