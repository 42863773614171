import { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { isNil, isEmpty } from 'lodash'

import { useAppState } from '@/stores'
import { formatBIUnit, numberFormat } from '@/utils/format'
import { useMarketData } from '@/utils/dataHooks'
import { incentiveDataEnum } from '@/config/constants/assets'

import InfoIcon from '@/public/svg/icon_question_mark.svg'

import Skeleton from '@/components/Common/Skeleton'
import HideShowButton from '@/components/HideShowButton'
import TableDepositsItem from './TableDepositsItem'
import TableDepositsMobile from './TableDepositsMobile'

const TableDeposits = () => {
  const {
    modalStore: { openInfoModal, titleEnum },
    walletAccountStore: { userAccount, uiPoolData, accountReserves, projectTokenPrice, methApy }
  } = useAppState()
  const [isShowMore, setIsShowMore] = useState(true)
  const { data: marketsData } = useMarketData()
  const markets = useMemo(() => marketsData?.markets || [], [marketsData])
  const { annualEmission } = incentiveDataEnum

  const supplying = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.supplying?.amount && Number(item.supplying.amount) > 0),
    [accountReserves]
  )

  const { totalSupplied, apySupplied, apyReward } = useMemo(() => {
    const getRewardApy = (address) => {
      const secondsPerYear = 60 * 60 * 24 * 365
      const market = markets.find((market) => market.id === address)
      if (!market || !projectTokenPrice) return null

      const totalAllocPoint = market?.totalAllocPoint ?? 0
      const supplyAllocPoint = market?.supplyAllocPoint ?? 0
      const totalSuppliedRC = market?.totalDepositBalanceUSD || 1

      const rewardSupplyRC = (supplyAllocPoint / totalAllocPoint) * annualEmission * projectTokenPrice
      const aprSupply = rewardSupplyRC / totalSuppliedRC
      const apy = (1 + aprSupply / secondsPerYear) ** secondsPerYear - 1

      return !Number.isFinite(apy) ? null : apy
    }

    return supplying.reduce(
      (acc, item) => {
        if (!item?.supplying?.amount || !item.price) return acc

        const _suppliedRC = item.price * formatBIUnit(item.supplying.amount, item.decimals)
        const _totalSupplied = acc.totalSupplied + _suppliedRC
        const _rewardAPY = getRewardApy(item.address)
        let _mEthApy = 0
        if (item.supplying.symbol.toLowerCase().includes('meth')) {
          _mEthApy = (_suppliedRC * methApy) / 100
        }
        const _apySupplied = acc.apySupplied + _suppliedRC * (Number(item.supplying.apy) / 1e27)
        const _apyReward = acc.apyReward + _suppliedRC * _rewardAPY + _mEthApy
        return { totalSupplied: _totalSupplied, apySupplied: _apySupplied, apyReward: _apyReward }
      },
      { totalSupplied: 0, apySupplied: 0, apyReward: 0 }
    )
  }, [supplying, methApy, annualEmission, projectTokenPrice, markets])

  const avgAPY = (apySupplied * 100) / totalSupplied + (apyReward * 100) / totalSupplied

  const collateralUSD = formatBIUnit(userAccount?.totalCollateralETH, 18)

  if (!isEmpty(accountReserves) && totalSupplied === 0) {
    return (
      <div className="card w-full flex-1 md:mt-8 md:w-auto lg:mt-0">
        <div className="p-4 md:p-8">
          <div className="text900 pb-4 text-lg font-semibold">Your supplies</div>
          <p className="text400 text-base font-medium">Nothing supplied yet</p>
        </div>
      </div>
    )
  }

  return (
    <div className="card overflow-visible">
      <div className="box-border block px-4 py-4 md:px-6 md:pb-0 md:pt-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="text900 box-border flex-1 border-0 text-lg font-semibold">Your supplies</div>
          </div>
          <HideShowButton isShow={isShowMore} toggleButton={setIsShowMore} className="sm:invisible" />
        </div>
      </div>
      <div className="block px-4 py-4 md:px-6 md:py-5">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-wrap items-center gap-2 md:gap-4">
            <div className="text600 border200 box-border rounded border p-[7px] text-sm font-normal leading-4">
              Balance{' '}
              {!isEmpty(accountReserves) || accountReserves === null ? (
                <span className="text900">${numberFormat(totalSupplied, { isSymbolHide: true })}</span>
              ) : (
                <Skeleton text={20} />
              )}
              <span className="text900 ml-1"> </span>
            </div>
            <div className="text600 border200 box-border rounded border p-[7px] text-sm font-normal leading-4">
              APY
              <span className="text900 ml-1">
                {!isEmpty(accountReserves) || accountReserves === null ? (
                  <>{numberFormat(avgAPY)}%</>
                ) : (
                  <Skeleton text={10} />
                )}
              </span>
            </div>
            <div className="text600 border200 box-border rounded border p-[7px] text-sm font-normal leading-4">
              <div className="flex items-center">
                <div className="box-border border-0">
                  Collateral
                  <span className="text900 ml-1">
                    {(!isEmpty(accountReserves) || accountReserves === null) &&
                    !isNil(userAccount?.totalCollateralETH) ? (
                      <>${numberFormat(collateralUSD, { isSymbolHide: true })}</>
                    ) : (
                      <Skeleton text={20} />
                    )}
                  </span>
                </div>
                <button
                  className="ml-2"
                  type="button"
                  aria-label="info"
                  onClick={() => openInfoModal(titleEnum.collateral)}
                >
                  <InfoIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-border hidden p-4 md:flex md:px-6 md:py-3 ">
        <div className="text600 flex w-full text-xs">
          <div className="flex w-[20%]">Assets</div>
          <div className="flex w-[19%] justify-end">Balance</div>
          <div className="flex w-[16%] justify-end">APY</div>
        </div>
      </div>
      <div className="hidden md:block">
        {accountReserves &&
          supplying.map(
            (supply) =>
              supplying.length > 0 && (
                <TableDepositsItem
                  asset={supply.supplying.symbol}
                  contract={supply.address}
                  key={supply.address}
                  collateral={uiPoolData?.userReserve?.[supply.address]?.usageAsCollateralEnabledOnUser}
                />
              )
          )}
        {isEmpty(accountReserves) && accountReserves !== null && (
          <div className="flex flex-col">
            {Array(6)
              .fill({})
              .map((_, index) => (
                <div
                  className="box-border flex items-center justify-between px-4 py-[18px]"
                  key={'skeleton' + index} // eslint-disable-line
                >
                  <Skeleton className="!my-0 h-9 rounded-full" classNameWrapper="max-w-full" />
                </div>
              ))}
          </div>
        )}
      </div>
      {isShowMore && (
        <div className="px-0 py-0 md:hidden">
          {isEmpty(accountReserves) && <TableDepositsMobileSkeleton />}
          {accountReserves &&
            supplying.map(
              (supply) =>
                supplying.length > 0 && (
                  <TableDepositsMobile
                    asset={supply.supplying.symbol}
                    contract={supply.address}
                    key={supply.address}
                    collateral={uiPoolData?.userReserve?.[supply.address]?.usageAsCollateralEnabledOnUser}
                  />
                )
            )}
        </div>
      )}
    </div>
  )
}

export default observer(TableDeposits)

const TableDepositsMobileSkeleton = () => (
  <div className="flex flex-col">
    {Array(6)
      .fill({})
      .map((_, index) => (
        <div
          className="box-border flex w-full flex-col md:hidden"
          key={'skeleton' + index} // eslint-disable-line
        >
          <div className="mt-2 p-4">
            <div className="flex w-full flex-col gap-4">
              <div className="flex items-center gap-4">
                <Skeleton className="h-[40px] w-[40px] rounded-full" text={10} />
                <Skeleton className="h-[23px]" text={16} />
              </div>
              <Skeleton className="!my-0 !mb-2 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 !mb-[2px] h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              <div className="flex justify-between gap-4">
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
)
