import MinusIcon from '@/public/svg/minus-icon.svg'
import PlusIcon from '@/public/svg/plus-icon.svg'

interface IDropDownProps {
  isShow: boolean
  toggleButton: (isShow: boolean) => void
  name?: string
  className?: string
}

const HideShowButton: React.FC<IDropDownProps> = ({ isShow, toggleButton, name, className }) => (
  <div className={className}>
    <button
      onClick={() => toggleButton(!isShow)}
      type="button"
      className="btn-link flex items-center text-end text-base font-medium"
    >
      <span className="mr-2">{isShow ? (name ? `Hide ${name}` : 'Hide') : name ? `Show ${name}` : 'Show'}</span>
      {isShow ? <MinusIcon /> : <PlusIcon />}
    </button>
  </div>
)

export default HideShowButton
