import Image from 'next/legacy/image'

import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'
import { AssetsIcons } from '@/config/constants/assets'

import AccountAmounts from '@/components/Common/AccountAmounts'
import VestButton from '@/components/VestButton'
import SupplyAPY from '@/components/Common/SupplyAPY'

interface ITableDepositsColumn {
  asset: string
  contract: string
  collateral?: boolean
}

const TableDepositsMobile = ({ asset, contract }: ITableDepositsColumn) => {
  const {
    modalStore: { openFinOperationsModal }
  } = useAppState()

  return (
    <div className="mt-2 flex w-full flex-col p-4 md:hidden">
      <div className="text900">
        <div className="mb-4 flex items-center justify-between text-base font-bold">
          <div className="flex items-center">
            {asset && (
              <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={`${asset} icon`} width={40} height={40} />
            )}
            <div className="ml-2">{asset}</div>
          </div>
        </div>
        <div className="text600 mb-2 flex justify-between text-base font-normal">
          <span className="">Supply balance</span>
          <div className="flex flex-1 flex-col items-end justify-end">
            <div className="text900 flex w-full flex-1 justify-end">
              <AccountAmounts
                assetAddress={contract}
                type="supply"
                formatOptions={{
                  isSymbolHide: true
                }}
              />
            </div>
            <div className="text600 flex w-full flex-1 justify-end text-xs">
              <AccountAmounts
                assetAddress={contract}
                type="supply"
                isRC
                formatOptions={{
                  isSymbolHide: true,
                  minDigits: 2,
                  maxDigits: 2
                }}
              />
            </div>
          </div>
        </div>
        <div className="text600 flex justify-between text-base font-normal">
          <span className="">Supply APY</span>
          <div>
            <SupplyAPY contractAddress={contract} symbol={asset} positionType="mobile" />
          </div>
        </div>
        {/* <div className="text600 flex justify-between items-center text-sm font-normal">
          Collateral
          <Switch asset={asset} assetAddress={contract} collateral={collateral} />
        </div> */}
      </div>
      <div className="mt-4 flex flex-col gap-4">
        <button
          type="button"
          onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.withdraw })}
          className="btn-primary"
        >
          Withdraw
        </button>
        <div className="flex gap-4">
          <button
            type="button"
            onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.deposit })}
            className="btn-secondary w-1/2"
          >
            Deposit
          </button>
          {/* <VestButton reserve={contract} className="h-[40px] w-1/2 text-sm font-medium" /> */}
        </div>
      </div>
    </div>
  )
}

export default TableDepositsMobile
