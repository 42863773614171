import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'

import { AssetsIcons } from '@/config/constants/assets'
import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'

import AccountAmounts from '@/components/Common/AccountAmounts'
import SupplyAPY from '@/components/Common/SupplyAPY'
// import AssetToSupplyCollateral from './AssetToSupplyCollateral'

interface ITableDepositsColumn {
  accReserve: any
}

const AssetsToSupplyItemMobile = ({ accReserve }: ITableDepositsColumn) => {
  const router = useRouter()
  const {
    modalStore: { openFinOperationsModal }
  } = useAppState()

  const asset = accReserve.supplying.symbol
  const contractAddress = accReserve.address

  return (
    <div aria-hidden className="mt-2 flex w-full flex-col gap-4 p-4 md:hidden">
      <div className="flex items-center">
        <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={asset} width={40} height={40} />
        <div className="text900 ml-2 text-base font-bold">{asset}</div>
      </div>
      <div className="text600 flex justify-between text-base font-normal">
        <span className="">Supply balance</span>
        <div className="flex flex-1 flex-col items-end">
          <div className="text900 flex w-full flex-1 justify-end">
            <AccountAmounts
              assetAddress={contractAddress}
              type="balance"
              formatOptions={{
                isSymbolHide: true
              }}
            />
          </div>
          {/* <div className="text600 flex w-full flex-1 justify-end text-xs">
            <AccountAmounts
              assetAddress={contractAddress}
              type="balance"
              isRC
              formatOptions={{
                isSymbolHide: true,
                minDigits: 2,
                maxDigits: 2
              }}
            />
          </div> */}
        </div>
      </div>
      <div className="text600 flex justify-between text-base font-normal">
        <span className="">Supply APY</span>
        <div>
          <SupplyAPY contractAddress={contractAddress} symbol={asset} positionType="mobile" />
        </div>
      </div>
      {/* <div className="text600 flex flex-wrap items-center justify-between text-base font-normal">
        <span className="">Can be collateral</span>
        <div className="flex flex-col items-end">
          <span className="text900">
            <AssetToSupplyCollateral assetAddress={contractAddress} />
          </span>
        </div>
      </div> */}
      <div className="flex gap-4">
        <button
          type="button"
          onClick={() =>
            openFinOperationsModal({ asset, contract: contractAddress, method: finOperationsEnum.deposit })
          }
          className="btn-secondary w-1/2"
        >
          Supply
        </button>
        <button
          type="button"
          onClick={() =>
            router.push({
              pathname: '/marketdetail',
              query: { asset, contract: contractAddress }
            })
          }
          className="btn-secondary w-1/2"
        >
          Details
        </button>
      </div>
    </div>
  )
}

export default observer(AssetsToSupplyItemMobile)
