import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { useAccount } from 'wagmi'
import { isNil } from 'lodash'

import { useAppState } from '@/stores'
import { AccountReserveAmounts } from '@/stores/types'
import { INumberFormatOptions, formatBIUnit, numberFormat } from '@/utils/format'

import Skeleton from '@/components/Common/Skeleton'

export type AccountAmountsProps = {
  assetAddress: string
  type: 'supply' | 'borrow' | 'balance' | 'available'
  className?: string
  isRC?: boolean // Reference Currency amount
  rcSymbol?: string
  formatOptions?: INumberFormatOptions
}

const AccountAmounts: React.FunctionComponent<AccountAmountsProps> = ({
  assetAddress,
  type,
  className,
  isRC,
  rcSymbol = '$',
  formatOptions = {}
}) => {
  const {
    walletAccountStore: { accountReservesAmounts }
  } = useAppState()

  const { address } = useAccount()
  const assetAddress_ = assetAddress?.toLowerCase()

  const accountAmount = useMemo(() => {
    if (!address) return 0

    const accountReserveAmounts: AccountReserveAmounts = accountReservesAmounts?.[assetAddress_]

    if (isNil(accountReserveAmounts)) return null

    const priceRC = accountReserveAmounts?.price ?? 0

    let amount = 0

    if (type === 'supply') {
      amount = formatBIUnit(accountReserveAmounts.balances.supplied, accountReserveAmounts.decimals)
    }

    if (type === 'borrow') {
      amount = formatBIUnit(accountReserveAmounts.balances.borrowed, accountReserveAmounts.decimals)
    }

    if (type === 'balance') {
      amount = formatBIUnit(accountReserveAmounts.balances.availableToSupply, accountReserveAmounts.decimals)
    }

    if (type === 'available') {
      amount = formatBIUnit(accountReserveAmounts.balances.availableToBorrow, accountReserveAmounts.decimals)
    }

    if (isRC) {
      amount *= Number(priceRC)
    }

    return amount
  }, [address, assetAddress_, accountReservesAmounts, type, isRC])

  if (accountAmount === null) {
    return <Skeleton className="mr-2" />
  }

  return (
    <span className={className}>
      {isRC && <span className="mr-[2px]">{rcSymbol}</span>}
      {numberFormat(accountAmount, { ...formatOptions })}
    </span>
  )
}

export default observer(AccountAmounts)
