import Image from 'next/legacy/image'

import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'
import { AssetsIcons } from '@/config/constants/assets'

import AccountAmounts from '@/components/Common/AccountAmounts'
import BorrowAPY from '@/components/Common/BorrowAPY'

interface ITableBorrowsColumn {
  asset: string
  contract: string
}

const TableBorrowsMobile = ({ asset, contract }: ITableBorrowsColumn) => {
  const {
    modalStore: { openFinOperationsModal }
  } = useAppState()

  return (
    <div className="mt-2 flex w-full flex-col p-4 md:hidden">
      <div className="text900">
        <div className="mb-4 flex items-center justify-between text-base font-bold">
          <div className="flex items-center">
            {asset && (
              <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={`${asset} icon`} width={40} height={40} />
            )}
            <div className="ml-2">{asset}</div>
          </div>
        </div>
        <div className="text600 mb-2 flex justify-between text-base font-normal">
          <span className="">Debt</span>
          <div className="flex flex-1 flex-col items-end justify-end">
            <div className="text900 flex w-full flex-1 justify-end">
              <AccountAmounts
                assetAddress={contract}
                type="borrow"
                formatOptions={{
                  isSymbolHide: true
                }}
              />
            </div>
            <div className="text60 flex w-full flex-1 justify-end text-xs">
              <AccountAmounts
                assetAddress={contract}
                type="borrow"
                isRC
                formatOptions={{
                  isSymbolHide: true,
                  minDigits: 2,
                  maxDigits: 2
                }}
              />
            </div>
          </div>
        </div>
        <div className="text600 flex justify-between text-base font-normal">
          <span className="">Borrow APY</span>
          <div>
            <BorrowAPY contractAddress={contract} positionType="mobile" />
          </div>
        </div>
      </div>
      <div className="mt-4 flex gap-4">
        <button
          type="button"
          onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.repay })}
          className="btn-primary w-1/2"
        >
          Repay
        </button>
        <button
          type="button"
          onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.borrow })}
          className="btn-secondary w-1/2"
        >
          Borrow
        </button>
      </div>
    </div>
  )
}

export default TableBorrowsMobile
