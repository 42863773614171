import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'

import Dashboard from '@/views/Dashboard'
import Layout from '@/components/layout/Layout'
import ClientOnlyRender from '@/components/Common/ClientOnlyRender'
import { useEffect } from 'react'

const IndexPage: NextPage = () => {
  const router = useRouter()
  const { isConnected } = useAccount()

  useEffect(() => {
    if (!isConnected && !router?.query?.isClientClick) {
      router.push('/markets')
    }
  }, [router, isConnected])

  return (
    <Layout>
      <ClientOnlyRender>
        <Dashboard />
      </ClientOnlyRender>
    </Layout>
  )
}

export default IndexPage
