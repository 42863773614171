import React from 'react'

const SubHeader = ({ subHeaderDataArr }: { subHeaderDataArr?: any[] }) => (
  <div className="relative mt-8 h-heroSm overflow-x-auto rounded-base bg-icon-icon bg-[url('/image/hero.png')] bg-cover bg-center bg-no-repeat md:h-heroBase">
    <ul className="flex h-full w-full flex-nowrap items-center justify-center gap-2">
      {subHeaderDataArr.map((el, index) => (
        <React.Fragment key={el.title}>
          {!!el.value && (
            <>
              <div className="flex basis-1/2  flex-col items-center justify-between text-white-1">
                <h2 className="mb-4 w-[max-content] text-base">{el.title}</h2>
                <div className="">
                  <p className="w-[max-content] text-2xl font-semibold leading-none sm:text-4xl">{el.value}</p>
                </div>
              </div>
              {index < subHeaderDataArr.length - 1 && <span className="h-10 border-l border-white-400" />}
            </>
          )}
        </React.Fragment>
      ))}
    </ul>
  </div>
)

export default SubHeader
