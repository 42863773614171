import { useAccount } from 'wagmi'
import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'

import { biTokenAmountToReferenceCurrencyAmount } from '@/utils/format'
import { useAppState } from '@/stores'

import ConnectWalletButton from '@/components/ConnectWalletButton'
import Skeleton from '@/components/Common/Skeleton'
import InfoModalIcon from '@/public/svg/info-modal.svg'

import AssetsToBorrowItemMobile from './AssetsToBorrowItemMobile'

function AssetsToBorrowMobile() {
  const { isConnected } = useAccount()

  const {
    walletAccountStore: { accountReserves, prices }
  } = useAppState()

  const [accReservesSorted, setAccReservesSorted] = useState([])

  useEffect(() => {
    const accReservesDescending = Object.values(accountReserves).sort((a, b) => {
      if (
        biTokenAmountToReferenceCurrencyAmount(a.borrowing.amount, prices[a.address]?.usd, a.borrowing.decimals) <=
        biTokenAmountToReferenceCurrencyAmount(b.borrowing.amount, prices[b.address]?.usd, b.borrowing.decimals)
      ) {
        return 1
      }
      return -1
    })
    setAccReservesSorted(accReservesDescending)
  }, [accountReserves, prices]) // TODO Remake to use useMemo instead of

  const supplying = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.supplying?.amount && Number(item.supplying.amount) > 0),
    [accountReserves]
  )
  const totalSupplied = useMemo(
    () =>
      supplying.reduce((acc, item) => {
        if (!item?.supplying?.amount || !item.price) return acc
        const _suppliedRC = item.price * (Number(item.supplying.amount) / 10 ** item.decimals)
        return acc + _suppliedRC
      }, 0),
    [supplying]
  )

  if (!isConnected) {
    return (
      <div className="flex justify-center p-8 text-center md:flex lg:flex-col">
        <ConnectWalletButton />
      </div>
    )
  }

  return (
    <div className="md:inline">
      {!isEmpty(accountReserves) && totalSupplied === 0 && (
        <div className="m-4 flex items-center rounded-sm bg-colorPrimaryLight p-4 md:m-8 md:mt-4">
          <InfoModalIcon className="min-h-[20px] min-w-[20px] stroke-colorPrimaryDark" />
          <p className="ml-3 text-base font-medium text-colorPrimaryDark">
            To borrow you need to supply any asset to be used as collateral.
          </p>
        </div>
      )}
      {isEmpty(accountReserves) && <AssetsToBorrowItemMobileSkeleton />}
      {Object.keys(accReservesSorted).length > 0
        ? Object.values(accReservesSorted).map((accReserve) => (
            <AssetsToBorrowItemMobile accReserve={accReserve} key={accReserve.address} />
          ))
        : null}
    </div>
  )
}

export default observer(AssetsToBorrowMobile)

const AssetsToBorrowItemMobileSkeleton = () => (
  <div className="flex flex-col">
    {Array(6)
      .fill({})
      .map((_, index) => (
        <div
          className="box-border flex w-full flex-col hover:bg-bg-gray-100 dark:hover:bg-white-100 md:hidden"
          key={'skeleton' + index} // eslint-disable-line
        >
          <div className="mt-2 p-4">
            <div className="flex w-full flex-col gap-4">
              <div className="flex items-center gap-4">
                <Skeleton className="h-[40px] w-[40px] rounded-full" text={10} />
                <Skeleton className="h-[23px]" text={16} />
              </div>
              <Skeleton className="!my-0 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <div className="flex justify-between gap-4">
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
)
