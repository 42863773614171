import WalletProtect from '@/components/Common/WalletProtect'
import DashboardDetail from '@/views/Dashboard/DasboardDetail/DashboardDetail'

const Dashboard = () => (
  <WalletProtect>
    <DashboardDetail />
  </WalletProtect>
)

export default Dashboard
