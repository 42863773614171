import { useState } from 'react'

import HideShowButton from '@/components/HideShowButton'
import AssetsToBorrowMobile from './AssetsToBorrowMobile'
import AssetToBorrowTable from './AssetToBorrow/AssetToBorrowTable'

type BorrowProps = {
  hideTitle?: boolean
}

function Borrow({ hideTitle }: BorrowProps) {
  const [isShowMore, setIsShowMore] = useState(true)

  return (
    <div>
      {!hideTitle && <div className="pb-6 text-2xl font-medium text-white-1">Borrow</div>}
      <div className="card hidden overflow-visible md:block">
        <div className="text900 box-border border-border-gray-200 px-6 py-4 text-lg font-semibold md:py-5">
          Assets to borrow
        </div>
        <AssetToBorrowTable />
      </div>
      <div className="card w-full md:hidden">
        <div className="text900 box-border flex items-center justify-between border-border-gray-200 px-6 py-4 text-lg font-semibold md:py-5">
          <span>Assets to borrow</span>
          <HideShowButton isShow={isShowMore} toggleButton={setIsShowMore} className="sm:invisible" />
        </div>
        {isShowMore && <AssetsToBorrowMobile />}
      </div>
    </div>
  )
}

export default Borrow
