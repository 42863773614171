import { useMemo } from 'react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'

import { AssetsIcons } from '@/config/constants/assets'
import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'

import BorrowAPY from '@/components/Common/BorrowAPY'
import AccountAmounts from '@/components/Common/AccountAmounts'

interface ITableBorrowColumn {
  accReserve: any
}

const AssetsToBorrowItemMobile = ({ accReserve }: ITableBorrowColumn) => {
  const router = useRouter()
  const {
    modalStore: { openFinOperationsModal },
    walletAccountStore: { reserves }
  } = useAppState()

  const borrowingEnabled = useMemo(
    () => reserves[accReserve.address].configuration.borrowingEnabled,
    [reserves, accReserve.address]
  )
  const asset = accReserve.borrowing.symbol
  const contractAddress = accReserve.address

  return (
    <div aria-hidden className="mt-2 flex w-full flex-col gap-4 p-4 md:hidden">
      <div className="flex items-center">
        <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={asset} width={40} height={40} />
        <div className="text900 ml-2 text-base font-bold">{asset}</div>
      </div>
      <div className="text600 flex justify-between text-base font-normal">
        <span className="">Available balance</span>
        <div className="flex flex-1 flex-col items-end">
          <div className="text900 flex w-full flex-1 justify-end">
            <AccountAmounts
              assetAddress={contractAddress}
              type="available"
              formatOptions={{
                isSymbolHide: true
              }}
            />
          </div>
          {/* <div className="flex w-full flex-1 justify-end text-xs font-normal dark:text-textGray-600">
            <AccountAmounts
              assetAddress={contractAddress}
              type="available"
              isRC
              formatOptions={{
                isSymbolHide: true,
                minDigits: 2,
                maxDigits: 2
              }}
            />
          </div> */}
        </div>
      </div>
      <div className="text600 flex justify-between text-base font-normal">
        <span className="">Borrow APY</span>
        <div>
          <BorrowAPY contractAddress={contractAddress} positionType="mobile" className="font-normal" />
        </div>
      </div>
      <div className="flex gap-4">
        <button
          type="button"
          disabled={!borrowingEnabled}
          onClick={() => openFinOperationsModal({ asset, contract: contractAddress, method: finOperationsEnum.borrow })}
          className="btn-secondary w-1/2"
        >
          Borrow
        </button>
        <button
          type="button"
          onClick={() =>
            router.push({
              pathname: '/marketdetail',
              query: { asset, contract: contractAddress }
            })
          }
          className="btn-secondary w-1/2"
        >
          Details
        </button>
      </div>
    </div>
  )
}

export default observer(AssetsToBorrowItemMobile)
