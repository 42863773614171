import Image from 'next/legacy/image'

import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'
import { AssetsIcons } from '@/config/constants/assets'

import AccountAmounts from '@/components/Common/AccountAmounts'
import SupplyAPY from '@/components/Common/SupplyAPY'

import VestButton from '@/components/VestButton'
// import Skeleton from '@/components/Common/Skeleton'
// import Switch from './Toggle'

interface ITableDepositsItem {
  asset: string
  contract: string
  collateral: boolean
}

const TableDepositsItem = ({
  asset,
  contract
  // collateral
}: ITableDepositsItem) => {
  const {
    modalStore: { openFinOperationsModal }
  } = useAppState()

  return (
    <div className="box-border flex items-center justify-between px-6 py-[18px]">
      <div className="text900 flex w-full items-center">
        <div className="flex w-[20%]  items-center">
          <div className="mr-2 flex items-center">
            {asset && <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={asset} width={32} height={32} />}
          </div>
          <div className="box-border border-0 text-left text-base font-semibold">{asset}</div>
        </div>
        <div className="box-border w-[20%] border-0">
          <div className="flex flex-col items-end">
            <div className="text-base font-semibold leading-5">
              <AccountAmounts
                assetAddress={contract}
                type="supply"
                formatOptions={{
                  isSymbolHide: true
                }}
              />
            </div>
            <div className="text700 text-sm font-normal leading-4">
              <AccountAmounts
                assetAddress={contract}
                type="supply"
                isRC
                formatOptions={{
                  isSymbolHide: true,
                  minDigits: 2,
                  maxDigits: 2
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex w-[17%] flex-col items-end">
          <SupplyAPY contractAddress={contract} symbol={asset} />
        </div>

        {/* <div className="box-border flex w-[12.5%] items-center border-0">
          <div className="flex items-center justify-between">
            <Switch asset={asset} assetAddress={contract} isEnabled={collateral} />
          </div>
        </div> */}
        <div className="ml-auto flex w-[46%] items-center justify-end">
          <div className="flex items-center justify-end  gap-2 text-right">
            <button
              type="button"
              onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.withdraw })}
              className="btn-primary-sm min-w-[70px]"
            >
              Withdraw
            </button>
            <button
              type="button"
              onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.deposit })}
              className="btn-secondary-sm min-w-[70px]"
            >
              Supply
            </button>
            {/* <div className="flex min-w-[97px] justify-center">
              <VestButton reserve={contract} className="h-[26px] min-w-[97px] text-xs font-medium lg:mt-0" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableDepositsItem
