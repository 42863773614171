import classNames from 'classnames'

const TabButtonsMobile = ({ onClick, isBorrowTab }) => (
  <div className="border300 mt-8 flex w-full rounded-full border bg-gray-200 p-1 dark:bg-card-c1 md:hidden">
    <button
      type="button"
      className={classNames('text500 box-border flex w-1/2 justify-center rounded-full p-2 font-medium', {
        'bg-white-1  text-textGray-700 dark:bg-bg-body dark:text-white-700': !isBorrowTab
      })}
      onClick={() => onClick(false)}
    >
      Supply
    </button>
    <button
      type="button"
      className={classNames('border300 text500 box-border flex w-1/2 justify-center rounded-full p-2 font-medium', {
        'bg-white-1  text-textGray-700 dark:bg-bg-body dark:text-white-700': isBorrowTab
      })}
      onClick={() => onClick(true)}
    >
      Borrow
    </button>
  </div>
)

export default TabButtonsMobile
