import { useMemo } from 'react'
import { observer } from 'mobx-react'

import { useAppState } from '@/stores'
import { numberFormat, formatBIUnit } from '@/utils/format'
import { useMarketData } from '@/utils/dataHooks'
import { incentiveDataEnum } from '@/config/constants/assets'

import SubHeader from '@/components/SubHeader'

type SunHeaderItemValueType = {
  title: string
  value: any
}
type SunHeaderItemsType = Record<string, SunHeaderItemValueType>

const headerDataInit: SunHeaderItemsType = {
  netWorth: { title: 'Net worth', value: '' },
  netApy: { title: 'Net APY', value: '' }
}

function TopHomePage() {
  const {
    walletAccountStore: { accountReserves, projectTokenPrice, methApy }
  } = useAppState()
  const { data: marketsData } = useMarketData()
  const markets = useMemo(() => marketsData?.markets || [], [marketsData])
  const { annualEmission } = incentiveDataEnum

  const supplying = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.supplying?.amount && Number(item.supplying.amount) > 0),
    [accountReserves]
  )
  const borrowing = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.borrowing?.amount && Number(item.borrowing.amount) > 0),
    [accountReserves]
  )

  const { totalSupplied, apySupplied } = useMemo(() => {
    const getSupplyRewardApy = (address) => {
      const secondsPerYear = 60 * 60 * 24 * 365
      const market = markets.find((market) => market.id === address)
      if (!market || !projectTokenPrice) return null

      const totalAllocPoint = market?.totalAllocPoint ?? 0
      const supplyAllocPoint = market?.supplyAllocPoint ?? 0
      const totalSuppliedRC = market?.totalDepositBalanceUSD || 1

      const rewardSupplyRC = (supplyAllocPoint / totalAllocPoint) * annualEmission * projectTokenPrice
      const aprSupply = rewardSupplyRC / totalSuppliedRC
      const apy = (1 + aprSupply / secondsPerYear) ** secondsPerYear - 1

      return !Number.isFinite(apy) ? null : apy
    }

    return supplying.reduce(
      (acc, item) => {
        if (!item?.supplying?.amount || !item.price) return acc

        const _suppliedRC = item.price * formatBIUnit(item.supplying.amount, item.decimals)
        const _totalSupplied = acc.totalSupplied + _suppliedRC
        const _rewardAPY = getSupplyRewardApy(item.address)
        let _mEthApy = 0
        if (item.supplying.symbol.toLowerCase().includes('meth')) {
          _mEthApy = (_suppliedRC * methApy) / 100
        }
        const _apySupplied =
          acc.apySupplied + _suppliedRC * (Number(item.supplying.apy) / 1e27) + _suppliedRC * _rewardAPY + _mEthApy
        return { totalSupplied: _totalSupplied, apySupplied: _apySupplied }
      },
      { totalSupplied: 0, apySupplied: 0 }
    )
  }, [supplying, methApy, annualEmission, projectTokenPrice, markets])

  const { totalBorrowed, apyBorrowed } = useMemo(() => {
    const getBorrowRewardApy = (address) => {
      const secondsPerYear = 60 * 60 * 24 * 365
      const market = markets.find((market) => market.id === address)
      if (!market || !projectTokenPrice) return null

      const totalAllocPoint = market?.totalAllocPoint ?? 0
      const borrowAllocPoint = market?.borrowAllocPoint ?? 0
      const totalBorrowedRC = market?.totalBorrowBalanceUSD || 1

      const rewardBorrowRC = (borrowAllocPoint / totalAllocPoint) * annualEmission * projectTokenPrice
      const aprBorrow = rewardBorrowRC / totalBorrowedRC
      const apy = (1 + aprBorrow / secondsPerYear) ** secondsPerYear - 1

      return !Number.isFinite(apy) ? null : apy
    }

    return borrowing.reduce(
      (acc, item) => {
        if (!item?.borrowing?.amount || !item.price) return acc

        const _borrowedRC = item.price * formatBIUnit(item.borrowing.amount, item.decimals)
        const _totalBorrowed = acc.totalBorrowed + _borrowedRC
        const _rewardAPY = getBorrowRewardApy(item.address)
        const _apyBorrowed =
          acc.apyBorrowed + _borrowedRC * (Number(item.borrowing.apy) / 1e27) - _borrowedRC * _rewardAPY
        return { totalBorrowed: _totalBorrowed, apyBorrowed: _apyBorrowed }
      },
      { totalBorrowed: 0, apyBorrowed: 0 }
    )
  }, [borrowing, annualEmission, projectTokenPrice, markets])

  const subHeaderData = useMemo(() => {
    headerDataInit.netWorth.value = `$${numberFormat(totalSupplied - totalBorrowed)}`
    headerDataInit.netApy.value = `${numberFormat(((apySupplied - apyBorrowed) * 100) / totalSupplied)}%`

    return Object.values(headerDataInit)
  }, [totalSupplied, apySupplied, totalBorrowed, apyBorrowed])

  return <SubHeader subHeaderDataArr={subHeaderData} />
}

export default observer(TopHomePage)
