import { useQuery, useSubscription } from '@apollo/client'
import { useNetwork } from 'wagmi'
import configJson from '@/config/index.json'
import {
  MarketsDataDocument,
  GetTokenDataDocument,
  GetAccountReservesDataDocument,
  GetFeesDailySnapshotsAndMarketsDocument,
  StakersDataDocument,
  MetaDataDocument,
  MetaDataSDocument
} from '~/.graphclient'

export const useTotalValueLocked = (trimDecimals = 0) => {
  const { data: totalValueLockedForMarkets } = useQuery(MarketsDataDocument)

  const totalValueLocked =
    totalValueLockedForMarkets?.markets
      ?.reduce((acc: number, market: any) => acc + Number(market.totalValueLockedUSD), 0)
      ?.toFixed(trimDecimals) || 0

  return totalValueLocked
}

export const useTotalValueBorrowed = (trimDecimals = 0) => {
  const { data: totalValueLockedForMarkets } = useQuery(MarketsDataDocument)

  const totalValueBorrowed =
    totalValueLockedForMarkets?.markets
      ?.reduce((acc: number, market: any) => acc + Number(market.totalBorrowBalanceUSD), 0)
      ?.toFixed(trimDecimals) || 0

  return totalValueBorrowed
}

export const useTokenData = (tokenAddress: string) => {
  const { data: tokenData } = useQuery(GetTokenDataDocument, {
    variables: {
      id: tokenAddress?.toLowerCase()
    }
  })

  return tokenData?.token
}

export const useProjectTokenData = () => {
  const network = useNetwork()
  const config = configJson.networks[network?.chain?.id]
  const projectTokenAddress = config?.ProjectToken
  const tokenData = useTokenData(projectTokenAddress)

  return tokenData
}

export const useMarketData = () => {
  const { data, refetch } = useQuery(MarketsDataDocument)

  return { data, refetch }
}

export const useAccountReservesData = (account: string) => {
  const { data: accountReservesData, refetch } = useQuery(GetAccountReservesDataDocument, {
    variables: {
      id: account?.toLowerCase()
    }
  })

  return { accountReserves: accountReservesData?.account, refetch }
}

export const useFeesDailySnapshotsAndMarketsData = (days: number) => {
  const { data: feesDailySnapshotsAndMarketsData } = useQuery(GetFeesDailySnapshotsAndMarketsDocument, {
    variables: {
      days
    }
  })

  return feesDailySnapshotsAndMarketsData
}

export const useStakersDataQuery = (limit = 1000) => {
  const {
    data: stakersData,
    refetch,
    fetchMore
  } = useQuery(StakersDataDocument, {
    variables: {
      first: limit
    }
  })

  return { stakersData, refetch, fetchMore }
}

export const useMetaDataQuery = (interval = 0) => {
  const { data: metaData, refetch, fetchMore } = useQuery(MetaDataDocument, { pollInterval: interval })

  return { metaData, refetch, fetchMore }
}

// Doesn't work without WS endpoint
export const useMetaDataSubscription = () => {
  const { data: metaData } = useSubscription(MetaDataSDocument)

  return { metaData }
}
