import { useState } from 'react'

import HideShowButton from '@/components/HideShowButton'
import AssetsToSupplyMobile from './components/TabDeposit/AssetsToSupplyMobile'
import AssetToSupplyTable from './components/TabDeposit/AssetToSupply/AssetToSupplyTable'

type DepositProps = {
  hideTitle?: boolean
}

export default function Deposit({ hideTitle }: DepositProps) {
  const [isShowMore, setIsShowMore] = useState(true)

  return (
    <div className="w-full">
      {!hideTitle && <div className="mb-6 text-2xl font-medium">Deposit</div>}
      <div className="card overflow-visible">
        <div className="border200 text900 box-border flex items-center justify-between px-6 py-4 text-lg font-semibold md:py-5">
          <h2>Assets to supply</h2>
          <div className="md:hidden">
            <HideShowButton isShow={isShowMore} toggleButton={setIsShowMore} className="sm:invisible" />
          </div>
        </div>
        <div className="hidden md:block">
          <AssetToSupplyTable />
        </div>
        <div className="w-full md:hidden">{isShowMore && <AssetsToSupplyMobile />}</div>
      </div>
    </div>
  )
}
