import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'

import { AssetsIcons } from '@/config/constants/assets'
import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'

import AccountAmounts from '@/components/Common/AccountAmounts'
import SupplyAPY from '@/components/Common/SupplyAPY'

// import AssetToSupplyCollateral from '../AssetToSupplyCollateral'

interface AssetToSupplyTableItemProps {
  accReserve: any
}

const AssetToSupplyTableItem = ({ accReserve }: AssetToSupplyTableItemProps) => {
  const router = useRouter()
  const {
    modalStore: { openFinOperationsModal }
  } = useAppState()

  const asset = accReserve.supplying.symbol
  const contractAddress = accReserve.address

  return (
    <div className="text900 grid grid-cols-4 font-semibold">
      <div className="flex items-center py-5 pl-2 md:pl-6">
        <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={asset} width={32} height={32} />
        <div className="ml-2">{asset}</div>
      </div>
      <div className="flex flex-col items-end justify-center py-4 pr-2 md:text-left lg:pr-10">
        <div className="flex flex-col flex-wrap items-start">
          <div className="flex w-full flex-1">
            <AccountAmounts
              assetAddress={contractAddress}
              type="balance"
              formatOptions={{
                isSymbolHide: true
              }}
            />
          </div>
          {/* <div className="text700 flex w-full flex-1 text-xs font-normal">
            <AccountAmounts
              assetAddress={contractAddress}
              type="balance"
              isRC
              formatOptions={{
                isSymbolHide: true,
                maxDigits: 2
              }}
            />
          </div> */}
        </div>
      </div>
      <div className="flex flex-col items-end justify-center py-3 pr-2 lg:pr-8">
        <SupplyAPY contractAddress={contractAddress} symbol={asset} />
      </div>
      <div className="flex items-center justify-end pr-6">
        {/* <div className="">
          <AssetToSupplyCollateral assetAddress={contractAddress} />
        </div> */}
        <div className="items-center justify-end text-right text-white-1 lg:flex">
          <button
            type="button"
            onClick={() =>
              openFinOperationsModal({ asset, contract: contractAddress, method: finOperationsEnum.deposit })
            }
            className="btn-secondary-sm mb-1 ml-2 min-w-[60px] lg:mb-0"
          >
            Supply
          </button>
          <button
            type="button"
            onClick={() =>
              router.push({
                pathname: '/marketdetail',
                query: { asset, contract: contractAddress }
              })
            }
            className="btn-secondary-sm  ml-2 min-w-[60px]"
          >
            Details
          </button>
        </div>
      </div>
    </div>
  )
}

export default observer(AssetToSupplyTableItem)
