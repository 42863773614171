import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import { useAppState } from '@/stores'

import { formatBIUnit } from '@/utils/format'
import Skeleton from '@/components/Common/Skeleton'
import InfoModalIcon from '@/public/svg/info-modal.svg'
import AssetsToSupplyItemMobile from './AssetsToSupplyItemMobile'

const AssetsToSupplyMobile = () => {
  const {
    walletAccountStore: { accountReserves, userAccount }
  } = useAppState()
  const [accReservesSorted, setAccReservesSorted] = useState([])
  const [isShowZeroBalance, setIsShowZeroBalance] = useState(false)

  const totalBalance = useMemo(
    () =>
      isEmpty(accountReserves)
        ? 0
        : Object.values(accountReserves).reduce((acc, item) => {
            if (!item?.balance) return acc
            return acc + formatBIUnit(item?.balance)
          }, 0),
    [accountReserves]
  )

  useEffect(() => {
    let accReservesDescending = Object.values(accountReserves).sort((a, b) => (a.balance <= b.balance ? 1 : -1))
    if (!isShowZeroBalance && totalBalance !== 0) {
      accReservesDescending = accReservesDescending.filter(
        (reserve) => formatBIUnit(reserve.balance, reserve.decimals) !== 0
      )
    }
    if (totalBalance === 0) {
      setIsShowZeroBalance(true)
    }
    setAccReservesSorted(accReservesDescending)
  }, [accountReserves, isShowZeroBalance, totalBalance])

  return (
    <div className="md:inline">
      {!isEmpty(accReservesSorted) && totalBalance === 0 && (
        <div className="m-4 flex items-center rounded-sm bg-colorPrimaryLight p-4 md:m-6 md:mt-4">
          <InfoModalIcon className="min-h-[20px] min-w-[20px] stroke-colorPrimaryDark" />
          <p className="ml-3 text-base font-medium text-colorPrimaryDark">
            Your wallet is empty. Purchase or transfer assets.
          </p>
        </div>
      )}
      {!isEmpty(accReservesSorted) && totalBalance !== 0 && (
        <div className="px-6 pb-4">
          <label htmlFor="checkboxIsShowZeroBalance" className="flex items-center">
            <input
              type="checkbox"
              name="checkboxIsShowZeroBalance"
              checked={isShowZeroBalance}
              id="checkboxIsShowZeroBalance"
              onChange={() => setIsShowZeroBalance(!isShowZeroBalance)}
              className="opacity-50 checked:opacity-100"
            />
            <span className="text700 ml-2 text-sm font-medium">Show assets with 0 balance</span>
          </label>
        </div>
      )}
      {isEmpty(accReservesSorted) && <AssetsToSupplyItemMobileSkeleton />}
      {!isEmpty(accReservesSorted) &&
        userAccount?.account &&
        Object.keys(accReservesSorted).length > 0 &&
        Object.values(
          accReservesSorted.map((accReserveSorted) => (
            <AssetsToSupplyItemMobile accReserve={accReserveSorted} key={accReserveSorted.address} />
          ))
        )}
    </div>
  )
}

export default observer(AssetsToSupplyMobile)

const AssetsToSupplyItemMobileSkeleton = () => (
  <div className="flex flex-col">
    {Array(6)
      .fill({})
      .map((_, index) => (
        <div
          className="box-border flex w-full flex-col hover:hover:bg-bg-gray-100 dark:hover:bg-white-200 md:hidden"
          key={'skeleton' + index} // eslint-disable-line
        >
          <div className="mt-2 p-4">
            <div className="flex w-full flex-col gap-4">
              <div className="flex items-center gap-4">
                <Skeleton className="h-[40px] w-[40px] rounded-full" text={10} />
                <Skeleton className="h-[23px]" text={16} />
              </div>
              <Skeleton className="!my-0 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-[23px] rounded-second" classNameWrapper="max-w-full" />
              <div className="flex justify-between gap-4">
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
)
