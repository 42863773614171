import { useState } from 'react'
import classNames from 'classnames'

import Deposit from '@/views/Deposit'
import Borrow from '@/views/Borrow'
import HeroDasboard from '@/views/Dashboard/DasboardDetail/HeroDasboard'
// import LendRewards from '@/views/Dashboard/DasboardDetail/LendRewards'
import TabButtonsMobile from '@/views/Dashboard/DasboardDetail/TabButtonsMobile'

// import CrossChainSupplyModal from '@/components/Modal/Modals/CrossChainSupply/CrossChainSupplyModal'

import TableDeposits from './Table/TableDeposit/TableDeposits'
import TableBorrows from './Table/TableBorrow/TableBorrows'

const DashboardDetail = () => {
  const [isBorrowTab, setIsBorrowTab] = useState(false)

  return (
    <div>
      <HeroDasboard />
      <TabButtonsMobile onClick={setIsBorrowTab} isBorrowTab={isBorrowTab} />
      <div className="my-8 flex flex-col flex-wrap sm:w-full sm:justify-between lg:flex-row lg:gap-8">
        <div className={classNames('w-full flex-1 md:block md:w-auto', { hidden: isBorrowTab })}>
          <TableDeposits />
        </div>
        <div className={classNames('flex-1 md:flex md:w-auto', { hidden: !isBorrowTab })}>
          <div className="w-full flex-1 md:w-auto">
            <TableBorrows />
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col gap-10">
        <LendRewards />
      </div> */}
      <div className="flex flex-col flex-wrap gap-8 sm:w-full sm:justify-between lg:flex-row lg:gap-8">
        <div className={classNames('w-full flex-1 md:block md:w-auto', { hidden: isBorrowTab })}>
          <Deposit hideTitle />
        </div>
        <div className={classNames('w-full flex-1 md:block md:w-auto', { hidden: !isBorrowTab })}>
          <Borrow hideTitle />
        </div>
      </div>
      {/* <CrossChainSupplyModal /> */}
    </div>
  )
}

export default DashboardDetail
