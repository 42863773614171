import { useMemo } from 'react'
import Image from 'next/legacy/image'

import { useAppState } from '@/stores'
import { finOperationsEnum } from '@/config/constants/finOperations'
import { AssetsIcons } from '@/config/constants/assets'

import AccountAmounts from '@/components/Common/AccountAmounts'
import BorrowAPY from '@/components/Common/BorrowAPY'

interface ITableBorrowsColumn {
  asset: string
  contract: string
}

function TableBorrowsColumn({ asset, contract }: ITableBorrowsColumn) {
  const {
    modalStore: { openFinOperationsModal },
    walletAccountStore: { reserves }
  } = useAppState()

  const borrowingEnabled = useMemo(() => reserves[contract].configuration.borrowingEnabled, [reserves, contract])

  return (
    <div className="box-border flex items-center justify-between px-6 py-[18px]">
      <div className="text900 flex w-full items-center">
        <div className="flex w-[20%] items-center">
          <div className="mr-2 flex items-center">
            {asset && <Image src={AssetsIcons[asset] || AssetsIcons.DEFAULT} alt={asset} width={32} height={32} />}
          </div>
          <div className="box-border border-0 text-left text-base font-semibold">{asset}</div>
        </div>
        <div className="box-border w-[20%] border-0">
          <div className="flex flex-col items-end">
            <div className="text-base font-semibold leading-5">
              <AccountAmounts
                assetAddress={contract}
                type="borrow"
                formatOptions={{
                  isSymbolHide: true
                }}
              />
            </div>
            <div className="text700 text-sm font-normal leading-4">
              <AccountAmounts
                assetAddress={contract}
                type="borrow"
                isRC
                formatOptions={{
                  isSymbolHide: true,
                  minDigits: 2,
                  maxDigits: 2
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex w-[17%] flex-col items-end">
          <BorrowAPY contractAddress={contract} />
        </div>
        <div className="flex w-[43%] items-center justify-end">
          <div className="flex items-center justify-end text-right">
            <button
              type="button"
              onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.repay })}
              className="btn-primary-sm mb-1 ml-2 min-w-[60px] lg:mb-0"
            >
              Repay
            </button>
            <button
              type="button"
              disabled={!borrowingEnabled}
              onClick={() => openFinOperationsModal({ asset, contract, method: finOperationsEnum.borrow })}
              className="btn-secondary-sm  ml-2 min-w-[60px]"
            >
              Borrow
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableBorrowsColumn
