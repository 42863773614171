import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import { useAppState } from '@/stores'

import { formatBIUnit } from '@/utils/format'
import Skeleton from '@/components/Common/Skeleton'
import InfoModalIcon from '@/public/svg/info-modal.svg'
import AssetToSupplyTableItem from './AssetToSupplyTableItem'

const AssetToSupplyTable = () => {
  const {
    walletAccountStore: { accountReserves, userAccount }
  } = useAppState()
  const [accReservesSorted, setAccReservesSorted] = useState([])
  const [isShowZeroBalance, setIsShowZeroBalance] = useState(true)

  const totalBalance = useMemo(
    () =>
      isEmpty(accountReserves)
        ? 0
        : Object.values(accountReserves).reduce((acc, item) => {
            if (!item?.balance) return acc
            return acc + formatBIUnit(item?.balance)
          }, 0),
    [accountReserves]
  )

  useEffect(() => {
    // const accReservesDescending = Object.values(accountReserves).sort((a, b) => (a.balance <= b.balance ? 1 : -1))
    const accReservesDescending = Object.values(accountReserves)
    // if (!isShowZeroBalance && totalBalance !== 0) {
    //   accReservesDescending = accReservesDescending.filter(
    //     (reserve) => formatBIUnit(reserve.balance, reserve.decimals) !== 0
    //   )
    // }
    if (totalBalance === 0) {
      setIsShowZeroBalance(true)
    }
    setAccReservesSorted(accReservesDescending)
  }, [accountReserves, isShowZeroBalance, totalBalance])

  return (
    <div className="md:inline">
      {!isEmpty(accReservesSorted) && totalBalance === 0 && (
        <div className="m-4 flex items-center rounded-sm bg-colorPrimaryLight p-4 md:m-6 md:mt-4">
          <InfoModalIcon className="min-h-[20px] min-w-[20px] stroke-colorPrimaryDark" />
          <p className="ml-3 text-base font-medium text-colorPrimaryDark">
            Your wallet is empty. Purchase or transfer assets.
          </p>
        </div>
      )}
      {!isEmpty(accReservesSorted) && totalBalance !== 0 && !isShowZeroBalance && (
        <div className="px-6 pb-4">
          <label htmlFor="checkboxIsShowZeroBalance" className="flex items-center">
            <input
              type="checkbox"
              name="checkboxIsShowZeroBalance"
              checked={isShowZeroBalance}
              id="checkboxIsShowZeroBalance"
              onChange={() => setIsShowZeroBalance(!isShowZeroBalance)}
              className="opacity-50 checked:opacity-100"
            />
            <span className="text700 ml-2 text-sm font-medium">Show assets with 0 balance</span>
          </label>
        </div>
      )}
      <div className="flex w-full flex-col">
        <div>
          <div className="text600 grid grid-cols-4 text-xs font-medium">
            <div className="py-3 text-center md:pl-6 md:text-left">Assets </div>
            <div className="items-center py-3 pr-2 text-center md:text-right lg:pr-10">Balance</div>
            <div className="flex items-center justify-center py-3 pr-2 md:justify-end lg:pr-8">
              <div className="flex items-center gap-2">
                APY
                {/* <button onClick={sortData} type="button">
                    <ArrowDownIcon className={isSort ? 'rotate-180' : 'rotate-0'} />
                  </button> */}
              </div>
            </div>
            {/* <div className="hidden items-center py-3 text-left md:table-cell">Can be collateral</div> */}
          </div>
        </div>
        <div>
          {isEmpty(accReservesSorted) && (
            <div>
              <div>
                <div className="flex w-full flex-col">
                  {Array(6)
                    .fill({})
                    .map((_, index) => (
                      <div
                        className="box-border flex items-center justify-between px-4 py-5"
                        key={'skeleton' + index} // eslint-disable-line
                      >
                        <Skeleton className="!my-0 h-8 rounded-full" classNameWrapper="max-w-full" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {!isEmpty(accReservesSorted) &&
            userAccount?.account &&
            Object.keys(accReservesSorted).length > 0 &&
            Object.values(accReservesSorted).map((accReserveSorted) => (
              <AssetToSupplyTableItem accReserve={accReserveSorted} key={accReserveSorted.address} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default observer(AssetToSupplyTable)
