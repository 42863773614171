import { useAccount } from 'wagmi'
import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'

import { toUsd } from '@/utils/format'
import { useAppState } from '@/stores'

import ConnectWalletButton from '@/components/ConnectWalletButton'
import Skeleton from '@/components/Common/Skeleton'
import InfoModalIcon from '@/public/svg/info-modal.svg'

import AssetToBorrowTableItem from './AssetToBorrowTableItem'

function AssetToBorrowTable() {
  const { isConnected } = useAccount()
  const {
    walletAccountStore: { accountReserves, userAccount, prices }
  } = useAppState()

  const [accReservesSorted, setAccReservesSorted] = useState([])

  useEffect(() => {
    const accReservesDescending = Object.values(accountReserves).sort((a, b) => {
      if (
        toUsd(a.borrowing.amount, prices[a.address?.toLowerCase()]?.usd, a.borrowing.decimals) <=
        toUsd(b.borrowing.amount, prices[b.address?.toLowerCase()]?.usd, b.borrowing.decimals)
      ) {
        return 1
      }
      return -1
    })
    setAccReservesSorted(accReservesDescending)
  }, [accountReserves, prices])

  const supplying = useMemo(
    () => Object.values(accountReserves).filter((item) => item?.supplying?.amount && Number(item.supplying.amount) > 0),
    [accountReserves]
  )
  const totalSupplied = useMemo(
    () =>
      supplying.reduce((acc, item) => {
        if (!item?.supplying?.amount || !item.price) return acc
        const _suppliedRC = item.price * (Number(item.supplying.amount) / 10 ** item.decimals)
        return acc + _suppliedRC
      }, 0),
    [supplying]
  )

  if (!isConnected) {
    return (
      <div className="flex justify-center p-8 text-center md:flex lg:flex-col">
        <ConnectWalletButton />
      </div>
    )
  }

  return (
    <div className="md:inline">
      <div className="flex w-full flex-col">
        {!isEmpty(accountReserves) && totalSupplied === 0 && (
          <div className="m-4 flex items-center rounded-sm bg-colorPrimaryLight p-4 md:m-6 md:mt-4">
            <InfoModalIcon className="min-h-[20px] min-w-[20px] stroke-colorPrimaryDark" />
            <p className="ml-3 text-base font-medium text-colorPrimaryDark">
              To borrow you need to supply any asset to be used as collateral.
            </p>
          </div>
        )}
        <div>
          <div className="text600 grid grid-cols-4 border-border-gray-300 text-xs font-medium">
            <div className="py-3 pl-6">Assets</div>
            <div className="py-3 pr-2 text-right lg:pr-10">Available</div>
            <div className="py-3 pr-2 text-right lg:pr-8">APY</div>
          </div>
        </div>
        <div>
          {isEmpty(accountReserves) && (
            <div>
              <div>
                <div className="flex w-full flex-col">
                  {Array(6)
                    .fill({})
                    .map((_, index) => (
                      <div
                        className="box-border flex items-center justify-between px-4 py-5"
                        key={'skeleton' + index} // eslint-disable-line
                      >
                        <Skeleton className="!my-0 h-8 rounded-full" classNameWrapper="max-w-full" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {!isEmpty(accountReserves) &&
            userAccount?.account &&
            Object.keys(accReservesSorted).length > 0 &&
            Object.values(accReservesSorted).map((accReserveSorted) => (
              <AssetToBorrowTableItem accReserve={accReserveSorted} key={accReserveSorted.address} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default observer(AssetToBorrowTable)
